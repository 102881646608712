import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const sliderClass = '.js-services-slider';

const sliderServices = new Swiper(sliderClass, {
    modules: [Autoplay, Navigation],
    loop: true,
    speed: 650,
    spaceBetween: 80,
    navigation: {
        nextEl: '.services-slider--next',
        prevEl: '.services-slider--prev',
    },
    autoplay: {
        delay: 1500,
    },
    breakpoints: {
        500: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        991: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 3.85,
        },
    },
});
